import confetti from "canvas-confetti";

export function useConfetti() {
  const startConfetti = () => {
    confetti({
      particleCount: 30,
      spread: 50,
      origin: { y: 0.6 },
      colors: [
        "#9333ea", // Purple
        "#3b82f6", // Blue
        "#ec4899", // Pink
        "#f59e0b", // Amber
        "#10b981", // Emerald
      ],
      gravity: 0.7,
      scalar: 0.9,
      ticks: 150
    });
  };

  return { startConfetti };
} 