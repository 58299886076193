import { cn } from "@/lib/utils";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface InDemandBannerProps {
  purchaseCount?: number;
  className?: string;
}

const StarIcon = () => (
  <svg
    className="w-[14px] h-[14px] text-[#FF553C]"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.80729 0.655781C9.19147 -0.218594 9.8084 -0.218594 10.193 0.655781L12.6447 6.22902L18.1772 7.06861C19.0648 7.20264 19.2653 7.86154 18.6265 8.54377L14.588 12.8227L15.5787 18.9209C15.7303 19.8773 15.216 20.2677 14.4315 19.8067L9.50003 16.8973L4.56876 19.8067C3.78387 20.2677 3.26973 19.8773 3.42116 18.9209L4.41182 12.8227L0.373554 8.54377C-0.265227 7.86154 -0.0649666 7.20264 0.822905 7.06861L6.35495 6.22902L8.80729 0.655781Z"
      fill="currentColor"
    />
  </svg>
);

const FireIcon = () => (
  <svg
    className="w-5 h-5 text-[#FF553C]"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM16.35 14.35L12 18.7L7.65 14.35C6.85 13.55 6.35 12.45 6.35 11.25C6.35 10.05 6.85 8.95 7.65 8.15C8.45 7.35 9.55 6.85 10.75 6.85C11.95 6.85 13.05 7.35 13.85 8.15L14.5 8.8L15.15 8.15C15.95 7.35 17.05 6.85 18.25 6.85C19.45 6.85 20.55 7.35 21.35 8.15C22.15 8.95 22.65 10.05 22.65 11.25C22.65 12.45 22.15 13.55 21.35 14.35L17 18.7L12.65 14.35L12 13.7L11.35 14.35L7 18.7L2.65 14.35C1.85 13.55 1.35 12.45 1.35 11.25C1.35 10.05 1.85 8.95 2.65 8.15C3.45 7.35 4.55 6.85 5.75 6.85C6.95 6.85 8.05 7.35 8.85 8.15L9.5 8.8L10.15 8.15C10.95 7.35 12.05 6.85 13.25 6.85C14.45 6.85 15.55 7.35 16.35 8.15C17.15 8.95 17.65 10.05 17.65 11.25C17.65 12.45 17.15 13.55 16.35 14.35Z"
      fill="currentColor"
    />
  </svg>
);

interface DemandMessage {
  content: React.ReactNode;
  showOnFree?: boolean;
}

const demandMessages: DemandMessage[] = [
  {
    content: (
      <div className="inline-block !text-[12px]">
        ❤️‍🔥 <span className="text-[#2196f3] font-semibold">In demand!</span>
        {" "}729 <br />
        purchased today
      </div>
    ),
    showOnFree: false
  },
  {
    content: (
      <div className="inline-block !text-[12px]">
        👍 <span className="font-semibold">20,345</span> people <br />
        <span className="text-[#2196f3] font-semibold">2+ times</span>
      </div>
    ),
    showOnFree: false
  },
  {
    content: (
      <div className="inline-block !text-[12px] leading-tight">
        🌟 <span className="font-semibold">895</span>{" "}
        <span className="text-[#2196f3] font-semibold"> people </span>
        <br />
        gave a <span className="text-[#2196f3] font-semibold">5-star</span>{" "}
        review
      </div>
    ),
    showOnFree: false
  }
];

export function InDemandBanner({ className }: InDemandBannerProps) {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const location = useLocation();
  const isFreePage = location.pathname.includes('free');

  const getServiceType = () => {
    if (location.pathname.includes('likes')) return 'likes';
    if (location.pathname.includes('views')) return 'views';
    return 'shares';
  };

  const getFreeMessages = (): DemandMessage[] => {
    const serviceType = getServiceType();
    const randomNumber = Math.floor(Math.random() * (400 - 250) + 250);
    
    return [
      {
        content: (
          <div className="inline-block !text-[12px] leading-tight">
            🔥 <span className="font-semibold">{randomNumber}</span>{" "}
            <span className="text-[#2196f3] font-semibold">people</span>{" "}
            got free {serviceType} <br />
            today
          </div>
        ),
        showOnFree: true
      }
    ];
  };

  const currentMessages = isFreePage ? getFreeMessages() : demandMessages;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prev) => (prev + 1) % currentMessages.length);
    }, 4000); // Change message every 4 seconds

    return () => clearInterval(interval);
  }, [currentMessages.length]);

  return (
    <div
      className={cn(
        "flex flex-col items-center gap-2 mb-4 max-w-[1005px] mx-auto",
        className
      )}
    >
      {/* Avatar Stack */}
      <div className="flex items-center gap-1 justify-center w-full">
        <div className="flex -space-x-3 ml-3">
          <img
            src="/avatars/av_1.avif"
            alt="User avatar"
            className="w-8 h-8 rounded-full border-2 border-white"
          />
          <img
            src="/avatars/av_2.avif"
            alt="User avatar"
            className="w-8 h-8 rounded-full border-2 border-white"
          />
          <img
            src="/avatars/av_3.avif"
            alt="User avatar"
            className="w-8 h-8 rounded-full border-2 border-white"
          />
          <img
            src="/avatars/av_4.avif"
            alt="User avatar"
            className="w-8 h-8 rounded-full border-2 border-white"
          />
        </div>

        {/* Animated In Demand Text */}
        <div className="items-center text-[14px] ml-4 relative h-[40px]">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentMessageIndex}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              {currentMessages[currentMessageIndex].content}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>

      {/* Feature Banner */}
      {!isFreePage && (
        <div className="flex items-center justify-between gap-4 bg-[#F8FAFC] rounded-[16px] px-4 py-3 mt-2">
          <div className="flex items-center gap-1.5">
            <div className="bg-[#38BDF8] rounded-full p-0.5">
              <svg
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 6L9 17L4 12"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <span className="text-[#4B5563] text-xs leading-tight">
              1-minute
              <br />
              Checkout
            </span>
          </div>

          <div className="flex items-center gap-1.5">
            <div className="bg-[#38BDF8] rounded-full p-0.5">
              <svg
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 6L9 17L4 12"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <span className="text-[#4B5563] text-xs leading-tight">
              100% Growth
              <br />
              Guarantee
            </span>
          </div>

          <div className="flex items-center gap-1.5">
            <div className="bg-[#38BDF8] rounded-full p-0.5">
              <svg
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 6L9 17L4 12"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <span className="text-[#4B5563] text-xs leading-tight">
              5-Star
              <br />
              Support
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
