import posthog from "posthog-js";
import { HomePage } from "@/HomePage";
import { TwicsyLandingPage } from "./twicsy-landing-page";

export function LandingPage() {
  // If landing-page-conversion is 'twicsy', show the Twicsy variant
  // Otherwise, show the control (current HomePage)
  // const isTwicsyVariant = posthog.getFeatureFlag('landing-page-conversion') === 'twicsy';

  return <TwicsyLandingPage />;
}
