import React, { useMemo, lazy, Suspense } from "react";
import { cn } from "@/lib/utils";
import { Card } from "@/components/ui/card";
import Icon from "./icon";
import { motion } from "framer-motion";
import { useSnapshot } from "valtio";
import state, { Followers, PostBasedProduct, Services } from "@/store/state";

// Lazy load Lottie and animations
const LottieWrapper = lazy(() => import('@/components/lottie-wrapper'));

// Import Lottie animations
import followersAnimation from "@/assets/lotties/followers.json";
import likesAnimation from "@/assets/lotties/likes.json";
import viewsAnimation from "@/assets/lotties/views.json";
import sharesAnimation from "@/assets/lotties/shares.json";

interface ProveSourceCardProps {
  name: string;
  country: string;
  timeAgo: string;
  onClick?: () => void;
}

export const ProveSourceCard = React.memo(function ProveSourceCard({
  name,
  country,
  timeAgo,
  onClick,
}: ProveSourceCardProps) {
  const { cart } = useSnapshot(state);
  
  const getPromotionText = useMemo(() => (
    cart: ReadonlyArray<{
      readonly username: string;
      readonly products: {
        readonly [K in Services]: ReadonlyArray<Followers | PostBasedProduct>;
      };
    }>
  ) => {
    if (!cart.length) return "TikTok Followers";

    const userCart = cart[0];
    if (!userCart?.products) return "TikTok Followers";

    const {
      followers = [],
      likes = [],
      views = [],
      shares = [],
    } = userCart.products;

    if (followers.length > 0) return "TikTok Followers";
    if (likes.length > 0) return "TikTok Likes";
    if (views.length > 0) return "TikTok Views";
    if (shares.length > 0) return "TikTok Shares";

    return "TikTok Followers";
  }, []);

  const getLottieAnimation = useMemo(() => (promotionText: string) => {
    const getClonedAnimation = (animation: object) => JSON.parse(JSON.stringify(animation));
    
    switch (promotionText) {
      case "TikTok Followers":
        return getClonedAnimation(followersAnimation);
      case "TikTok Likes":
        return getClonedAnimation(likesAnimation);
      case "TikTok Views":
        return getClonedAnimation(viewsAnimation);
      case "TikTok Shares":
        return getClonedAnimation(sharesAnimation);
      default:
        return getClonedAnimation(followersAnimation);
    }
  }, []);

  const getLottieScale = useMemo(() => (promotionText: string): number => {
    switch (promotionText) {
      case "TikTok Followers":
        return 1.8;
      case "TikTok Likes":
        return 1.3;
      case "TikTok Views":
        return 0.8;
      case "TikTok Shares":
        return 0.8;
      default:
        return 1.8;
    }
  }, []);

  const promotionText = useMemo(() => getPromotionText(cart), [cart, getPromotionText]);
  const lottieAnimation = useMemo(() => getLottieAnimation(promotionText), [promotionText, getLottieAnimation]);
  const scale = useMemo(() => getLottieScale(promotionText), [promotionText, getLottieScale]);

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{
        duration: 0.8,
        ease: "easeOut",
      }}
      onClick={onClick}
      id="prove-source-card"
      className="fixed top-4 left-0 right-0 mx-auto z-[99999] w-[343px] shadow-[0_0_0_3px_#FFFFFF] rounded-[12px] h-[76px] cursor-pointer"
    >
      <div className="w-full max-w-[343px]">
        <div className="flex flex-col">
          <div className={cn(
            "px-4 flex items-center justify-center text-center !text-body-2xs-m h-5",
            "rounded-t-[12px] relative overflow-hidden",
            "bg-[#7C3AED] text-white py-[2px]"
          )}>
            <div className="absolute inset-0 w-[200%] animate-shine bg-gradient-to-r from-transparent via-white/40 to-transparent"></div>
            <span className="relative z-10 flex items-center">
              <Icon icon="checkbox-circle-fill" className="w-4 h-4" />
              <span className="mx-[2px]">
                Verified by <span className="font-bold">ProveSource</span>
              </span>
            </span>
          </div>

          <Card className="rounded-t-none rounded-b-[8px] bg-gray-50 border-none shadow-lg p-3">
            <div className="flex items-center gap-3">
              <div className="rounded-full bg-gray-100 flex items-center justify-center">
                <Suspense fallback={<div className="w-8 h-8" />}>
                  <LottieWrapper
                    animationData={lottieAnimation}
                    scale={scale}
                  />
                </Suspense>
              </div>
              <div className="flex-1">
                <p className="text-body-2xs-r text-gray-400">
                  <span className="text-gray-900 text-body-2xs-m">{name}</span>{" "}
                  from {country} bought this product recently:{" "}
                  <span className="text-gray-900 text-body-2xs-m">
                    {promotionText}
                  </span>
                  .
                </p>
              </div>
              <span className="!text-detail text-gray-400 px-[6px] py-1 bg-white rounded-[6px] border border-gray-100 h-[24px] w-[52px]">
                {timeAgo}
              </span>
            </div>
          </Card>
        </div>
      </div>
    </motion.div>
  );
});
