import OpenReplay from "@openreplay/tracker";
import Mixpanel from "@/utils/mixpanel";
import state from "@/store/state";
import posthog from "posthog-js";

// Initialize OpenReplay with extended options
const tracker = new OpenReplay({
  projectKey: "eHYlojEHlLZpOXXakvhI", // Replace with your actual project key
  capturePerformance: true,
  // @ts-expect-error OpenReplay network options type mismatch
  network: {
    capturePayload: true,
  },
  crossdomain: {
    enabled: true,
  },
  defaultInputMode: 0,
  obscureTextNumbers: false,
  obscureTextEmails: false,
  obscureInputEmails: false,
  obscureInputDates: false,
});

// Start OpenReplay tracking
tracker.start();

interface DataLayerObject {
  event: string;
  email?: string;
  currency: string;
  transaction_id?: string;
  value: number;
  items: Array<{
    item_id: string;
    item_brand: string;
    index?: number;
    item_name: string;
    item_variant: string;
    item_category: string;
    price: number;
    quantity: number;
  }>;
}
export function trackBeginCheckout(totalAmount: number) {
  // DataLayer tracking
  try {
    const dataLayerObject: DataLayerObject = {
      event: "begin_checkout",
      currency: "USD",
      value: totalAmount,
      items: [
        {
          item_id:
            state.cart[0]?.products[state.currentSelectedService!]?.[0]
              ?.service_id || "",
          item_brand: "TikTok",
          index: 0,
          item_name: "TikTok Consulting",
          item_variant: "Follower-Like",
          item_category: "TikTok",
          price: totalAmount,
          quantity: 1,
        },
      ],
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataLayerObject);
  } catch (error) {
    console.error("Error in dataLayer tracking:", error);
  }

  // Mixpanel tracking
  try {
    Mixpanel.track("Begin Checkout", {
      totalAmount: totalAmount,
      currency: "USD",
      itemId:
        state.cart[0]?.products[state.currentSelectedService!]?.[0]
          ?.service_id || "",
      itemName: "TikTok Consulting",
      itemCategory: "TikTok",
    });
  } catch (error) {
    console.error("Error in Mixpanel tracking:", error);
  }

  // PostHog tracking
  try {
    posthog.capture("begin_checkout", {
      total_amount: totalAmount,
      currency: "USD",
      item_id:
        state.cart[0]?.products[state.currentSelectedService!]?.[0]
          ?.service_id || "",
      item_name: "TikTok Consulting",
      item_category: "TikTok",
    });
  } catch (error) {
    console.error("Error in PostHog tracking:", error);
  }
}

export function trackPurchase(
  paymentIntent: string,
  email: string,
  totalAmount: number
) {
  // DataLayer tracking
  try {
    const dataLayerObject: DataLayerObject = {
      event: "purchase",
      email: email,
      currency: "USD",
      transaction_id: paymentIntent,
      value: totalAmount,
      items: [
        {
          item_id:
            state.cart[0]?.products[state.currentSelectedService!]?.[0]
              ?.service_id || "",
          item_brand: "TikTok",
          index: 0,
          item_name: "TikTok Consulting",
          item_variant: "Follower-Like",
          item_category: "TikTok",
          price: totalAmount,
          quantity: 1,
        },
      ],
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataLayerObject);
  } catch (error) {
    console.error("Error in dataLayer tracking:", error);
  }

  // Mixpanel tracking
  try {
    Mixpanel.track("Purchase", {
      distinct_id: email,
      paymentIntent: paymentIntent,
      totalAmount: totalAmount,
      currency: "USD",
      itemId:
        state.cart[0]?.products[state.currentSelectedService!]?.[0]
          ?.service_id || "",
      itemName: "TikTok Consulting",
      itemCategory: "TikTok",
    });

    Mixpanel.setUserProperties({
      $email: email,
      $last_purchase_date: new Date().toISOString(),
    });
  } catch (error) {
    console.error("Error in Mixpanel tracking:", error);
  }

  // PostHog tracking
  try {
    posthog.capture("purchase", {
      distinct_id: email,
      payment_intent: paymentIntent,
      total_amount: totalAmount,
      currency: "USD",
      item_id:
        state.cart[0]?.products[state.currentSelectedService!]?.[0]
          ?.service_id || "",
      item_name: "TikTok Consulting",
      item_category: "TikTok",
    });
    posthog.identify(email, {
      email: email,
    });
  } catch (error) {
    console.error("Error in PostHog tracking:", error);
  }

  // OpenReplay tracking
  try {
    tracker.setUserID(email);
    if ("OpenReplay" in window && window.OpenReplay) {
      window.OpenReplay.setMetadata("mixpanelDistanceID", email);
    }
  } catch (error) {
    console.error("Error in OpenReplay tracking:", error);
  }
}

export function trackAccountSelection() {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "accountSelected",
    });
  } catch (error) {
    console.error("Error in dataLayer tracking:", error);
  }

  try {
    Mixpanel.track("Account Selected");
  } catch (error) {
    console.error("Error in Mixpanel tracking:", error);
  }

  try {
    posthog.capture("account_selected");
  } catch (error) {
    console.error("Error in PostHog tracking:", error);
  }
}

export function trackOrderPageLoad() {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "orderPageLoad",
    });
  } catch (error) {
    console.error("Error in dataLayer tracking:", error);
  }

  try {
    Mixpanel.track("Order Page Loaded");
  } catch (error) {
    console.error("Error in Mixpanel tracking:", error);
  }

  try {
    posthog.capture("order_page_loaded");
  } catch (error) {
    console.error("Error in PostHog tracking:", error);
  }
}

export function trackStartProcessPayment() {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "startProcessPayment",
    });
  } catch (error) {
    console.error("Error in dataLayer tracking:", error);
  }

  try {
    Mixpanel.track("Start Process Payment");
  } catch (error) {
    console.error("Error in Mixpanel tracking:", error);
  }

  try {
    posthog.capture("start_process_payment");
  } catch (error) {
    console.error("Error in PostHog tracking:", error);
  }
}

export function trackAddToCart(productPrice: number, serviceId: string) {
  try {
    const dataLayerObject: DataLayerObject = {
      event: "add_to_cart",
      currency: "USD",
      value: productPrice,
      items: [
        {
          item_id: serviceId,
          item_brand: "TikTok",
          item_name: "TikTok Consulting",
          item_variant: "Follower-Like",
          item_category: "TikTok",
          price: productPrice,
          quantity: 1,
        },
      ],
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataLayerObject);
  } catch (error) {
    console.error("Error in dataLayer tracking:", error);
  }

  try {
    Mixpanel.track("Add to Cart", {
      productPrice: productPrice,
      serviceId: serviceId,
      itemName: "TikTok Consulting",
      itemCategory: "TikTok",
    });
  } catch (error) {
    console.error("Error in Mixpanel tracking:", error);
  }

  try {
    posthog.capture("add_to_cart", {
      product_price: productPrice,
      service_id: serviceId,
      item_name: "TikTok Consulting",
      item_category: "TikTok",
    });
  } catch (error) {
    console.error("Error in PostHog tracking:", error);
  }
}

export function trackPageView(pageName: string) {
  try {
    Mixpanel.track("Page View", {
      pageName: pageName,
      url: window.location.href,
    });
  } catch (error) {
    console.error("Error in Mixpanel tracking:", error);
  }

  try {
    posthog.capture("page_view", {
      page_name: pageName,
      url: window.location.href,
    });
  } catch (error) {
    console.error("Error in PostHog tracking:", error);
  }
}

export function trackFreeStuffClaimed(email: string) {
  // DataLayer tracking
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "freeStuffClaimed",
      email: email,
    });
  } catch (error) {
    console.error("Error in dataLayer tracking:", error);
  }

  // Mixpanel tracking
  try {
    Mixpanel.track("free_stuff_claimed", {
      distinct_id: email,
      email: email,
    });

    Mixpanel.setUserProperties({
      $email: email,
      claimed_free_stuff: true,
    });
  } catch (error) {
    console.error("Error in Mixpanel tracking:", error);
  }

  // PostHog tracking
  try {
    posthog.capture("free_stuff_claimed", {
      distinct_id: email,
      email: email,
    });
    posthog.identify(email, {
      email: email,
    });
  } catch (error) {
    console.error("Error in PostHog tracking:", error);
  }
}

export function setPostHogGroups() {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const username = state.user?.username;

    // List of parameters to track as groups with their properties
    const groupParams = [
      {
        type: "utm_source",
        properties: {
          name: "UTM Source",
          first_seen: new Date().toISOString(),
          medium: urlParams.get("utm_medium") || "unknown",
          campaign: urlParams.get("utm_campaign") || "unknown",
          username: username || undefined,
        },
      },
      {
        type: "utm_campaign",
        properties: {
          name: "UTM Campaign",
          first_seen: new Date().toISOString(),
          source: urlParams.get("utm_source") || "unknown",
          medium: urlParams.get("utm_medium") || "unknown",
          username: username || undefined,
        },
      },
      {
        type: "utm_agid",
        properties: {
          name: "Ad Group ID",
          first_seen: new Date().toISOString(),
          campaign: urlParams.get("utm_campaign") || "unknown",
          username: username || undefined,
        },
      },
      {
        type: "device",
        properties: {
          name: "Device Type",
          first_seen: new Date().toISOString(),
          platform: navigator.platform,
          screen_width: window.screen.width,
          screen_height: window.screen.height,
          username: username || undefined,
        },
      },
      {
        type: "matchtype",
        properties: {
          name: "Match Type",
          first_seen: new Date().toISOString(),
          campaign: urlParams.get("utm_campaign") || "unknown",
          username: username || undefined,
        },
      },
      {
        type: "coupon",
        properties: {
          name: "Coupon",
          first_seen: new Date().toISOString(),
          username: username || undefined,
        },
      },
    ];

    // Set groups and their properties for each parameter if it exists
    groupParams.forEach(({ type, properties }) => {
      const value = urlParams.get(type);
      if (value) {
        // Set group with properties using recommended method
        posthog.group(type, value, {
          ...properties,
          value: value, // actual value from URL
          last_seen: new Date().toISOString(),
          referrer: document.referrer || "direct",
          landing_page: window.location.pathname,
          full_url: window.location.href,
        });
      }
    });
  } catch (error) {
    console.error("Error setting PostHog groups:", error);
  }
}
