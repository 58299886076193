export interface Review {
  id: string;
  rating: number;
  date: string;
  title: string;
  content: string;
  author: {
    name: string;
    country: string;
  };
}

export const reviews: Review[] = [
  {
    id: "1",
    rating: 5,
    date: "Dec 02, 2024",
    title: "Amazing Results",
    content: "honestly wasn't expecting much at first but wow... every time i buy followers from famepeak, i get like 1.5-2x more organic followers naturally. my videos are getting way more engagement now and everything looks super natural. definitely the best decision i've made for my tiktok!",
    author: {
      name: "Rolf",
      country: "us"
    }
  },
  {
    id: "2",
    rating: 5,
    date: "Nov 17, 2024",
    title: "Viral Success 🚀",
    content: "started with their smallest package just to test it out... now my videos are reaching thousands of people! the boost in visibility even helped me get some brand deals. seriously can't believe how well this worked",
    author: {
      name: "Ivy W.",
      country: "us"
    }
  },
  {
    id: "3",
    rating: 5,
    date: "Nov 10, 2024",
    title: "Great Service",
    content: "Famepeak's TikTok likes packages are incredible. Not only did I get the likes I ordered, but my videos started appearing on more For You pages. The organic engagement that followed was amazing. My latest video hit 100k views thanks to the initial boost from Famepeak!",
    author: {
      name: "Parker",
      country: "us"
    }
  },
  {
    id: "4",
    rating: 5,
    date: "Nov 05, 2024",
    title: "Results doubled! 🔥",
    content: "ngl was kinda skeptical at first but these results are insane fr! started with just the followers package and my views went crazy. then tried the likes package and now my engagement is through the roof. best money i've spent on my tiktok career no cap",
    author: {
      name: "Billie P.",
      country: "us"
    }
  },
  {
    id: "5",
    rating: 5,
    date: "Oct 28, 2024",
    title: "Business Growth",
    content: "As a small business owner, I needed to build my TikTok presence fast. Famepeak delivered beyond expectations. Their combination of likes and followers packages helped my content reach the right audience. My product videos are now getting shared consistently.",
    author: {
      name: "Marcus R.",
      country: "us"
    }
  },
  {
    id: "6",
    rating: 5,
    date: "Oct 15, 2024",
    title: "Worth Every Penny",
    content: "took me a while to convince myself to try this but i'm so glad i did! the engagement looks totally natural and my videos are doing better than ever. definitely recommend if you're on the fence about it",
    author: {
      name: "Sarah K.",
      country: "us"
    }
  },
  {
    id: "7",
    rating: 5,
    date: "Oct 08, 2024",
    title: "Professional Service",
    content: "The customer support team is fantastic. They helped me choose the right mix of TikTok services for my goals. Started with likes, then added followers, and now my content regularly hits the For You Page. The delivery is always quick and the results are consistent.",
    author: {
      name: "James T.",
      country: "us"
    }
  },
  {
    id: "8",
    rating: 5,
    date: "Oct 01, 2024",
    title: "Dance Videos Blowing Up",
    content: "yo these results are actually crazy... my dance videos went from getting like 500 views to hitting 50k+ regularly! tried the likes + shares combo and the algorithm is literally in love with my content now lmao. if ur a dancer u need this frfr",
    author: {
      name: "Emma L.",
      country: "us"
    }
  },
  {
    id: "9",
    rating: 5,
    date: "Sep 25, 2024",
    title: "Quality Service",
    content: "Famepeak's TikTok services are essential for any serious creator. Their followers are genuine, the likes come from real accounts, and the shares help push your content to new audiences. I've tried others, but nothing compares to Famepeak's quality.",
    author: {
      name: "David M.",
      country: "us"
    }
  },
  {
    id: "10",
    rating: 5,
    date: "Sep 18, 2024",
    title: "Reliable Results",
    content: "What I appreciate most about Famepeak is their consistency. Every time I use their services, I see real results. The followers are genuine, the engagement is authentic, and it has helped me reach a broader audience effectively.",
    author: {
      name: "Rachel B.",
      country: "us"
    }
  },
  {
    id: "11",
    rating: 5,
    date: "Sep 15, 2024",
    title: "Gaming Content 🎮",
    content: "bruh my gaming clips are finally getting the attention they deserve fr fr! went from barely any views to having clips go viral. the likes package is goated no cap. if you're a gaming content creator you need to try this asap",
    author: {
      name: "Tyler K.",
      country: "us"
    }
  },
  {
    id: "12",
    rating: 5,
    date: "Sep 10, 2024",
    title: "Beauty Content Success",
    content: "bestie... i literally can't even! my makeup tutorials were flopping before but after using famepeak they're all over fyp! started with 2k views now we're hitting 100k+ easy. period! if you're in the beauty space you NEED this.",
    author: {
      name: "Mia R.",
      country: "us"
    }
  }
]; 