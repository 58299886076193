import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { motion, AnimatePresence } from "framer-motion";
import { ViewsIcon, LikesIcon, SharesIcon } from "@/components/stat-icons";
import { useConfetti } from "@/hooks/use-confetti";
import { Input } from "@/components/ui/input";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import actions from "@/store/actions";
import state from "@/store/state";
import { trackFreeStuffClaimed } from "@/utils/analytics";

const formSchema = z.object({
  email: z.string().email({ message: "Please enter a valid email address" }),
});

interface FreeOfferDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  username?: string;
}

const FreeOfferDialog: React.FC<FreeOfferDialogProps> = ({
  open,
  onOpenChange,
  username = "@username",
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { startConfetti } = useConfetti();
  const [inputElement, setInputElement] = useState<HTMLInputElement | null>(
    null
  );
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  useEffect(() => {
    if (open) {
      startConfetti();
    }
  }, [open]);

  useEffect(() => {
    if (currentStep === 2 && inputElement) {
      // Small timeout to ensure the input is mounted and animation is complete
      setTimeout(() => {
        inputElement.focus();
      }, 100);
    }
  }, [currentStep, inputElement]);

  const handleAcceptDeal = () => {
    setCurrentStep(2);
  };

  const handleEmailSubmit = async (values: z.infer<typeof formSchema>) => {
    if (!state.user?.username) return;

    setIsSubmitting(true);
    try {
      await actions.createPromotionOrder(state.user.username, values.email);
      trackFreeStuffClaimed(values.email);
      setCurrentStep(3);
      startConfetti();
    } catch (error) {
      console.error("Error creating promotion order:", error);
      // You might want to show an error message here
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    if (currentStep !== 3) {
      setCurrentStep(1);
      form.reset();
    }
    onOpenChange(false);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-[327px] p-4 rounded-[12px]">
        <div className="flex justify-center space-x-2">
          {[1, 2, 3].map((step) => (
            <div
              key={step}
              className="relative h-1 w-16 rounded-full bg-gray-200 overflow-hidden"
            >
              <div
                className={cn(
                  "absolute inset-0 bg-purple-500 transition-transform duration-300",
                  {
                    "scale-x-100": currentStep >= step,
                    "scale-x-0": currentStep < step,
                  }
                )}
              />
            </div>
          ))}
        </div>

        <AnimatePresence mode="wait">
          {currentStep === 1 ? (
            <motion.div
              key="step1"
              initial={{ x: 0, opacity: 1 }}
              exit={{ x: -20, opacity: 0 }}
              className="space-y-4"
            >
              <DialogHeader className="space-y-2">
                <DialogTitle className="text-body-xs-m text-center">
                  🎉 Limited Time Exclusive Offer! 🎉
                </DialogTitle>
                <DialogDescription className="!text-gray-600 text-center text-body-2xs-r">
                  <span className="text-purple-500 font-medium">
                    Only 3 spots left!
                  </span>{" "}
                  Based on{" "}
                  <span className="font-bold text-gray-900">{username}</span>'s
                  potential, we've prepared a special boost package. Join 47
                  other creators who already claimed their boost:
                </DialogDescription>
              </DialogHeader>

              <div className="py-2">
                <motion.div
                  className="bg-gray-50 rounded-xl p-4"
                  animate={{
                    scale: [1, 1.05, 1, 1.05, 1, 1.05, 1],
                  }}
                  transition={{
                    duration: 2.5,
                    times: [0, 0.16, 0.33, 0.5, 0.66, 0.83, 1],
                    delay: 0.3,
                    ease: "easeInOut",
                  }}
                >
                  <div className="flex justify-between items-start">
                    <div className="flex flex-col items-center space-y-2">
                      <div className="scale-75">
                        <ViewsIcon />
                      </div>
                      <div className="text-xl font-bold text-purple-500">
                        500
                      </div>
                      <div className="text-sm text-gray-600">Views</div>
                    </div>
                    <div className="flex flex-col items-center space-y-2">
                      <div className="scale-75">
                        <LikesIcon />
                      </div>
                      <div className="text-xl font-bold text-purple-500">
                        100
                      </div>
                      <div className="text-sm text-gray-600">Likes</div>
                    </div>
                    <div className="flex flex-col items-center space-y-2">
                      <div className="scale-75">
                        <SharesIcon />
                      </div>
                      <div className="text-xl font-bold text-purple-500">
                        50
                      </div>
                      <div className="text-sm text-gray-600">Shares</div>
                    </div>
                  </div>
                </motion.div>
                <p className="text-xs text-gray-500 mt-2 text-center">
                  💡 <span className="font-medium">Pro tip:</span> Users who
                  claimed this boost saw a{" "}
                  <span className="text-purple-500 font-medium">
                    3x increase
                  </span>{" "}
                  in their engagement rate!
                </p>
              </div>

              <DialogFooter className="flex justify-between gap-3">
                <Button
                  onClick={handleClose}
                  variant="ghost"
                  className="flex-1 hover:bg-gray-50"
                >
                  Maybe Later
                </Button>
                <Button
                  onClick={handleAcceptDeal}
                  className="flex-1 bg-purple-500 hover:bg-purple-600 text-white"
                >
                  Claim Now
                </Button>
              </DialogFooter>
            </motion.div>
          ) : currentStep === 2 ? (
            <motion.div
              key="step2"
              initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -20, opacity: 0 }}
              className="space-y-4"
            >
              <DialogHeader className="space-y-2">
                <DialogTitle className="text-body-xs-m text-center">
                  📧 Stay Updated on Your Growth
                </DialogTitle>
                <DialogDescription className="!text-gray-600 text-center text-body-2xs-r">
                  Enter your email to track your free boost package and receive
                  exclusive growth insights for{" "}
                  <span className="font-bold text-gray-900">{username}</span>
                </DialogDescription>
              </DialogHeader>

              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(handleEmailSubmit)}
                  className="space-y-4"
                >
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            {...field}
                            ref={(e) => {
                              field.ref(e);
                              setInputElement(e);
                            }}
                            placeholder="Enter your email"
                            className={cn(
                              "rounded-[8px] h-10 placeholder:text-gray-400 text-body-2xs-r",
                              fieldState.error
                                ? "border-red-300"
                                : "border-gray-200"
                            )}
                            disabled={isSubmitting}
                          />
                        </FormControl>
                        {fieldState.error && (
                          <div className="text-xs text-red-300 mt-1 flex items-center gap-1">
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6 4V6M6 8H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            {fieldState.error.message}
                          </div>
                        )}
                      </FormItem>
                    )}
                  />

                  <DialogFooter className="flex justify-between gap-3">
                    <Button
                      onClick={handleClose}
                      variant="ghost"
                      className="flex-1 hover:bg-gray-50"
                      type="button"
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      className="flex-1 bg-purple-500 hover:bg-purple-600 text-white"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Processing..." : "Continue"}
                    </Button>
                  </DialogFooter>
                </form>
              </Form>
            </motion.div>
          ) : (
            <motion.div
              key="step3"
              initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -20, opacity: 0 }}
              className="space-y-4"
            >
              <DialogHeader className="space-y-2">
                <DialogTitle className="text-body-xs-m text-center">
                  🚀 Your Growth Journey Begins!
                </DialogTitle>
                <DialogDescription className="!text-gray-600 text-center text-body-2xs-r">
                  Smart choice! Your exclusive boost package will be delivered
                  to <span className="font-bold text-gray-900">{username}</span>{" "}
                  within 24 hours. Get ready to:
                  <div className="mt-3 bg-gray-50 rounded-xl p-3 space-y-2.5">
                    <div className="flex items-center space-x-3">
                      <div className="scale-75">
                        <ViewsIcon />
                      </div>
                      <span className="text-gray-700">Gain 500+ new views</span>
                    </div>
                    <div className="flex items-center space-x-3">
                      <div className="scale-75">
                        <LikesIcon />
                      </div>
                      <span className="text-gray-700">
                        Gain 100 authentic likes
                      </span>
                    </div>
                    <div className="flex items-center space-x-3">
                      <div className="scale-75">
                        <SharesIcon />
                      </div>
                      <span className="text-gray-700">
                        Get 50 profile shares
                      </span>
                    </div>
                  </div>
                </DialogDescription>
              </DialogHeader>

              <DialogFooter>
                <Button
                  onClick={handleClose}
                  className="w-full bg-purple-500 hover:bg-purple-600 text-white"
                >
                  Continue Shopping
                </Button>
              </DialogFooter>
            </motion.div>
          )}
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  );
};

export default FreeOfferDialog;
