import { Card } from "@/components/ui/card";

import { Button } from "@/components/ui/button";

import { useNavigate } from "react-router-dom";

interface ServiceCardProps {
  title: string;
  description: string;
  price: string;
  buttonText: string;
  reviews: number;
  color: string;
  icon: React.ReactNode;
  mainColor: string;
  bottomPosition?: string;
}

function ServiceCard({
  title,
  description,
  price,
  buttonText,
  reviews,
  color,
  icon,
  mainColor,
  bottomPosition = "-bottom-18",
}: ServiceCardProps) {
  const navigate = useNavigate();

  return (
    <Card
      className="p-8 text-center h-full border-none !rounded-2xl relative overflow-hidden"
      style={{ backgroundColor: color }}
    >
      <div className="relative bg-white p-6 pb-[66px] rounded-3xl">
        <div>
          <div className="absolute -top-4 -right-4">{icon}</div>
        </div>
        <h3 className="text-[24px] leading-[28px] font-semibold mb-4">{title}</h3>
        <p className="text-[#767676] mb-8 text-[15px]">{description}</p>

        <Button 
          className="w-full md:mb-4 mb-12 text-white"
          onClick={() => navigate("/order")}
        >
          {buttonText}
        </Button>

        <div className="hidden md:block">
          <div className="text-gray-500 mb-4">
            Starting at <span className="font-bold text-black">${price}</span>
          </div>

          <div className="flex items-center justify-center gap-1 mb-1">
            {[...Array(5)].map((_, i) => (
              <svg
                key={i}
                className="w-6 h-6 text-[#FF5C5C]"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
            ))}
          </div>

          <div className="font-normal text-[13px] pb-4" style={{ color: mainColor }}>
            <span className="font-medium">5 / 5</span>{" "}
            <span className="text-[#767676]">
              {" "}
              - based on {reviews} reviews
            </span>
          </div>
        </div>
      </div>

      {/* Bottom decorative elements */}
      <div className={`absolute ${bottomPosition} left-0 right-0 h-20 flex justify-center items-end`}>
        <svg
          width="220"
          height="230"
          viewBox="0 0 170 137"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M69.4661 98.6883C62.3276 92.7385 58.219 91.2 58.2429 82.2535L58.3828 39.9721C58.4257 23.9022 71.6889 11.6787 88.0071 12.6701C104.325 13.6617 117.519 27.4927 117.476 43.5627L117.436 48.6785L117.831 101.35L94.6921 96.9056C85.8671 98.5166 76.6045 104.638 69.4661 98.6883Z"
            fill="url(#paint0_linear_47_3472)"
          ></path>
          <path
            d="M69.4661 98.6883C62.3276 92.7385 58.219 91.2 58.2429 82.2535L58.3828 39.9721C58.4257 23.9022 71.6889 11.6787 88.0071 12.6701C104.325 13.6617 117.519 27.4927 117.476 43.5627L117.436 48.6785L117.831 101.35L94.6921 96.9056C85.8671 98.5166 76.6045 104.638 69.4661 98.6883Z"
            fill="#DFFCFF"
          ></path>
          <path
            d="M56.7142 107.455C47.679 107.253 39.2406 102.973 33.8327 95.8495L6.54266 59.9028C-3.17114 47.1077 -0.501808 28.9887 12.5048 19.4328C25.5114 9.877 43.9299 12.5029 53.6437 25.298L58.4684 31.6531L95.0961 78.9125L80.109 96.8858C74.3772 103.76 65.7495 107.657 56.7142 107.455Z"
            fill="url(#paint1_linear_47_3472)"
          ></path>
          <path
            d="M56.7142 107.455C47.679 107.253 39.2406 102.973 33.8327 95.8495L6.54266 59.9028C-3.17114 47.1077 -0.501808 28.9887 12.5048 19.4328C25.5114 9.877 43.9299 12.5029 53.6437 25.298L58.4684 31.6531L95.0961 78.9125L80.109 96.8858C74.3772 103.76 65.7495 107.657 56.7142 107.455Z"
            fill="#A0ECF4"
          ></path>
          <path
            d="M115.429 106.394C124.231 106.194 132.452 101.956 137.72 94.9012L164.306 59.3028C173.769 46.6317 171.169 28.6883 158.498 19.225C145.827 9.76174 127.883 12.3622 118.42 25.0334L113.72 31.3269L78.0366 78.1283L92.6372 95.9275C98.2212 102.735 106.626 106.595 115.429 106.394Z"
            fill="url(#paint2_linear_47_3472)"
          ></path>
          <path
            d="M115.429 106.394C124.231 106.194 132.452 101.956 137.72 94.9012L164.306 59.3028C173.769 46.6317 171.169 28.6883 158.498 19.225C145.827 9.76174 127.883 12.3622 118.42 25.0334L113.72 31.3269L78.0366 78.1283L92.6372 95.9275C98.2212 102.735 106.626 106.595 115.429 106.394Z"
            fill="#A0ECF4"
          ></path>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M92.3927 44.1111C89.0152 42.1179 84.8418 42.0342 81.3871 43.8904L49.909 60.804C44.4542 63.7349 42.4082 70.5329 45.3391 75.9877C48.27 81.4425 55.068 83.4885 60.5228 80.5576L76.5875 71.9258C76.0871 72.9615 75.8112 74.0955 75.8112 75.2843V127.258C75.8112 132.149 80.4817 136.114 86.2431 136.114C90.3777 136.114 93.9506 134.072 95.6385 131.111C97.0405 130.003 97.8763 128.608 97.8763 127.093V73.3858L109.655 80.3369C114.987 83.4841 121.862 81.7124 125.009 76.3795C128.157 71.0466 126.385 64.172 121.052 61.0247L92.3927 44.1111Z"
            fill="url(#paint3_linear_47_3472)"
          ></path>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M92.3927 44.1111C89.0152 42.1179 84.8418 42.0342 81.3871 43.8904L49.909 60.804C44.4542 63.7349 42.4082 70.5329 45.3391 75.9877C48.27 81.4425 55.068 83.4885 60.5228 80.5576L76.5875 71.9258C76.0871 72.9615 75.8112 74.0955 75.8112 75.2843V127.258C75.8112 132.149 80.4817 136.114 86.2431 136.114C90.3777 136.114 93.9506 134.072 95.6385 131.111C97.0405 130.003 97.8763 128.608 97.8763 127.093V73.3858L109.655 80.3369C114.987 83.4841 121.862 81.7124 125.009 76.3795C128.157 71.0466 126.385 64.172 121.052 61.0247L92.3927 44.1111Z"
            fill="white"
          ></path>
          <path
            d="M80.1275 23.3689C81.405 26.4194 84.9052 26.0796 86.0222 23.7112M89.0046 23.7112C90.2821 26.7617 93.7822 26.4219 94.8992 24.0535M74.2329 25.7269C80.1275 33.2191 96.3729 33.2571 101.11 26.1072"
            stroke="#57D3E0"
            stroke-width="1.82465"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M16.8098 36.4289C20.3659 38.5889 23.6769 35.6546 23.0623 32.4181M26.0976 30.2125C29.6538 32.3725 32.9648 29.4382 32.3501 26.2017M15.071 42.661C26.611 45.9271 39.0811 38.101 38.6145 27.3212"
            stroke="#3EB2BE"
            stroke-width="1.82465"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M142.643 26.2908C141.814 30.3681 145.696 32.4905 148.533 30.8167M151.636 32.9264C150.807 37.0037 154.689 39.1261 157.527 37.4522M136.676 30.8171C137.509 42.7814 145.764 46.7136 155.75 42.6258"
            stroke="#3EB2BE"
            stroke-width="1.82465"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_47_3472"
              x1="87.8533"
              y1="9.80589"
              x2="81.9462"
              y2="103.459"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFA3A3"></stop>
              <stop offset="1" stop-color="#FDDC5D"></stop>
            </linearGradient>
            <linearGradient
              id="paint1_linear_47_3472"
              x1="10.8128"
              y1="17.5846"
              x2="72.5859"
              y2="96.1918"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFA3A3"></stop>
              <stop offset="1" stop-color="#FDDC5D"></stop>
            </linearGradient>
            <linearGradient
              id="paint2_linear_47_3472"
              x1="160.146"
              y1="17.3946"
              x2="98.7418"
              y2="94.262"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFA3A3"></stop>
              <stop offset="1" stop-color="#FDDC5D"></stop>
            </linearGradient>
            <linearGradient
              id="paint3_linear_47_3472"
              x1="118.932"
              y1="157.527"
              x2="40.8339"
              y2="98.8856"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white"></stop>
              <stop offset="1" stop-color="#FFE8F6"></stop>
            </linearGradient>
          </defs>
        </svg>
      </div>
    </Card>
  );
}

export function FamepeakPackages({ mainColor }: { mainColor: string }) {
  return (
    <section className="w-full pt-16 px-4 md:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto text-center">
        {/* Top Label */}
        <div className="mb-4 text-left md:text-center">
          <h3 className="font-normal" style={{ color: mainColor }}>
            Famepeak's TikTok Packages
          </h3>
        </div>

        {/* Main Title */}
        <h2 className="text-[32px] md:text-4xl lg:text-5xl font-bold text-[#2C3338] mb-6 leading-tight text-left md:text-center">
          Choose the Way You Want to Grow:{" "}
          <br className="hidden md:block" /> Real TikTok Views, Followers, Likes!
        </h2>

        {/* Subtitle */}
        <p className="text-gray-600 text-lg mb-12 text-left md:text-center">
          All TikTok marketing campaigns are different, and that's why we
          give you a wide variety of options to choose from.
        </p>

        {/* Special Order Card */}
        <Card className="max-w-[576px] !rounded-7xl shadow-none mx-auto p-6 bg-white border-[rgba(178,199,194,.2)]">
          <div className="flex items-center gap-6">
            <div className="w-16 relative h-16 bg-[#E5F8FF] overflow-hidden rounded-full flex items-center justify-center flex-shrink-0">
              <svg
                className="absolute w-22 -left-[26px] -top-0"
                width="103"
                height="101"
                viewBox="0 0 103 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M71.3998 77.7153C76.3721 73.6779 79.2107 67.5775 79.0969 61.1735L78.5226 28.8568C78.3182 17.3537 68.8274 8.1944 57.3243 8.39882C45.8213 8.60325 36.662 18.094 36.8664 29.5971L36.9679 35.3104L37.3032 78.1227L53.6301 81.852C59.8743 83.2783 66.4275 81.7527 71.3998 77.7153Z"
                  fill="url(#paint0_linear_47_1771)"
                ></path>
                <path
                  d="M71.3998 77.7153C76.3721 73.6779 79.2107 67.5775 79.0969 61.1735L78.5226 28.8568C78.3182 17.3537 68.8274 8.1944 57.3243 8.39882C45.8213 8.60325 36.662 18.094 36.8664 29.5971L36.9679 35.3104L37.3032 78.1227L53.6301 81.852C59.8743 83.2783 66.4275 81.7527 71.3998 77.7153Z"
                  fill="#91EAF0"
                ></path>
                <path
                  d="M51.353 19.5227C52.6932 22.2366 55.871 21.7274 56.7568 19.5005M59.4811 19.3311C60.8213 22.045 63.9991 21.5358 64.8849 19.309M49.9385 24.7844C55.7485 31.2932 62.2478 29.8758 66.1684 23.0757"
                  stroke="#0C7C84"
                  stroke-width="1.13296"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <rect
                  x="70.6993"
                  y="19.1041"
                  width="11.6769"
                  height="20.7"
                  rx="3.4811"
                  transform="rotate(16.165 70.6993 19.1041)"
                  fill="#0C7C84"
                ></rect>
                <rect
                  opacity="0.4"
                  x="67.1367"
                  y="46.0889"
                  width="12.2077"
                  height="29.5156"
                  rx="6.10386"
                  transform="rotate(-8.3767 67.1367 46.0889)"
                  fill="#0C7C84"
                ></rect>
                <rect
                  x="69.2581"
                  y="38.6539"
                  width="12.2077"
                  height="29.5156"
                  rx="6.10386"
                  transform="rotate(-8.3767 69.2581 38.6539)"
                  fill="#91EAF0"
                ></rect>
              </svg>
            </div>
            <p className="text-[#757575] text-[15px] text-left">
              And if you want to implement a specialized TikTok marketing
              strategy, reach out to us. We love special orders!
            </p>
          </div>
        </Card>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12 mt-12 max-w-[1090px] mx-auto">
        <ServiceCard
          mainColor={mainColor}
          title="TikTok Likes Services"
          description="Our TikTok likes packages instantly make your posts more popular. And a high number of TikTok likes mushrooms quickly into more and more likes. Choose high-quality or premium likes; both are incredibly inexpensive."
          price="1.47"
          buttonText="Buy TikTok Likes"
          reviews={61}
          color="#cdf9fe"
          bottomPosition="-bottom-20"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="49"
              viewBox="0 0 40 49"
              fill="none"
              style={{ filter: "drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.1))" }}
            >
              <ellipse
                cx="20.5"
                cy="20.0078"
                rx="15.5"
                ry="15"
                fill="#59dbe6"
              />
              <path
                d="M20 0.0078125L0 5.83327V14.7792C0 30.4512 7.38909 38.455 20 48.0078C32.611 38.4493 40 30.4512 40 14.7792V5.83327L20 0.0078125ZM17.9677 32.1714L8.16168 22.5862L12.4318 18.3476L17.9616 23.7526L29.3834 12.5861L33.6592 16.8246L17.9677 32.1714Z"
                fill="#dbfffa"
              />
            </svg>
          }
        />
        <ServiceCard
          mainColor={mainColor}
          title="Cheap TikTok Followers"
          description="And by 'cheap,' we mean we offer high-quality or premium followers at very affordable prices. Our services are cost-effective to build a social media presence quickly and effectively."
          price="2.97"
          buttonText="Buy TikTok Followers"
          reviews={68}
          color="#b352ff"
          bottomPosition="-bottom-20"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="49"
              viewBox="0 0 40 49"
              fill="none"
              style={{ filter: "drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.1))" }}
            >
              <ellipse
                cx="20.5"
                cy="20.0078"
                rx="15.5"
                ry="15"
                fill="#8b60d1"
              />
              <path
                d="M20 0.0078125L0 5.83327V14.7792C0 30.4512 7.38909 38.455 20 48.0078C32.611 38.4493 40 30.4512 40 14.7792V5.83327L20 0.0078125ZM17.9677 32.1714L8.16168 22.5862L12.4318 18.3476L17.9616 23.7526L29.3834 12.5861L33.6592 16.8246L17.9677 32.1714Z"
                fill="#e2d3ff"
              />
            </svg>
          }
        />
        <ServiceCard
          mainColor={mainColor}
          title="TikTok Views Packages"
          description="TikTok video views, TikTok profile views, TikTok story views – fast growth in all of these metrics will bring increased credibility to your account and will 'spillover' to provide even more exposure on other social media platforms."
          price="1.99"
          buttonText="Buy TikTok Views"
          reviews={48}
          color="#fd8"
          bottomPosition="-bottom-[90px]"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="49"
              viewBox="0 0 40 49"
              fill="none"
              style={{ filter: "drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.1))" }}
            >
              <ellipse
                cx="20.5"
                cy="20.0078"
                rx="15.5"
                ry="15"
                fill="#debd71"
              />
              <path
                d="M20 0.0078125L0 5.83327V14.7792C0 30.4512 7.38909 38.455 20 48.0078C32.611 38.4493 40 30.4512 40 14.7792V5.83327L20 0.0078125ZM17.9677 32.1714L8.16168 22.5862L12.4318 18.3476L17.9616 23.7526L29.3834 12.5861L33.6592 16.8246L17.9677 32.1714Z"
                fill="#fff0b5"
              />
            </svg>
          }
        />
      </div>
    </section>
  );
}
