import React, { useState, useEffect, useCallback } from "react";
import { ProveSourceCard } from "./prove-source-card";
import { recentPurchases } from "@/data/recent-purchases";
import { AnimatePresence } from "framer-motion";
import { useSnapshot } from "valtio";
import state from "@/store/state";

export function ProveSourceNotification() {
  
  const [currentPurchase, setCurrentPurchase] = useState<number>(0);
  const [isVisible, setIsVisible] = useState(false);
  const snap = useSnapshot(state);

  const handleClick = useCallback(() => {
    setIsVisible(false);
  }, []);

  const getRandomTimeAgo = useCallback(() => {
    const minutes = Math.floor(Math.random() * 56) + 5;
    return `${minutes}m ago`;
  }, []);

  useEffect(() => {
    if (!snap.ipInfo?.country) return;

    let timeoutId: NodeJS.Timeout | null = null;
    let hideTimeoutId: NodeJS.Timeout | null = null;

    const scheduleNext = () => {
      timeoutId = setTimeout(() => {
        setIsVisible(true);
        
        hideTimeoutId = setTimeout(() => {
          setIsVisible(false);
          setCurrentPurchase((prev) => {
            return (prev + 1) % recentPurchases.length;
          });
          scheduleNext();
        }, 6000);
      }, Math.random() * 10000 + 10000);
    };

    // Initial show
    timeoutId = setTimeout(() => {
      setIsVisible(true);
      hideTimeoutId = setTimeout(() => {
        setIsVisible(false);
        setCurrentPurchase((prev) => (prev + 1) % recentPurchases.length);
        scheduleNext();
      }, 6000);
    }, 1000);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (hideTimeoutId) {
        clearTimeout(hideTimeoutId);
      }
    };
  }, [snap.ipInfo?.country]);

  if (!snap.ipInfo?.country) return null;

  return (
    <AnimatePresence mode="wait">
      {isVisible && (
        <ProveSourceCard
          key={currentPurchase}
          name={recentPurchases[currentPurchase].name}
          country={snap.ipInfo.country}
          timeAgo={getRandomTimeAgo()}
          onClick={handleClick}
        />
      )}
    </AnimatePresence>
  );
}
