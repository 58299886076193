"use client";

import { useState, useEffect } from "react";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

interface ReviewDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

interface FormData {
  email: string;
  review: string;
  name: string;
}

export function ReviewDialog({ isOpen, onClose }: ReviewDialogProps) {
  const [rating, setRating] = useState(5);
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    email: '',
    review: '',
    name: ''
  });
  const [errors, setErrors] = useState<Partial<FormData>>({});

  useEffect(() => {
    if (!isOpen) {
      setFormData({
        email: '',
        review: '',
        name: ''
      });
      setRating(5);
      setErrors({});
    }
  }, [isOpen]);

  const validateForm = () => {
    const newErrors: Partial<FormData> = {};
    
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email';
    }
    
    if (!formData.review?.trim()) {
      newErrors.review = 'Review is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      toast({
        title: "Please check your input",
        description: "Email and review are required fields.",
        variant: "destructive",
      });
      return;
    }

    setIsSubmitting(true);
    
    try {
      await new Promise(resolve => setTimeout(resolve, 1000));

      toast({
        title: "Thank you for your review!",
        description: "We have received your feedback and really appreciate you taking the time to share your thoughts.",
        className: "bg-[#0FC585] text-white border-none",
      });

      onClose();
      
      setFormData({
        email: '',
        review: '',
        name: ''
      });
      setRating(5);
      setErrors({});
      
    } catch (error) {
      toast({
        title: "Something went wrong",
        description: "Please try again later.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    // Kullanıcı yazmaya başladığında hata mesajını temizle
    if (errors[name as keyof FormData]) {
      setErrors(prev => ({ ...prev, [name]: undefined }));
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[800px] w-[95%] p-4 sm:p-8 overflow-hidden rounded-2xl">
        <button 
          onClick={onClose}
          className="absolute right-4 top-4 rounded-sm opacity-70 hover:opacity-100 transition-opacity focus:outline-none"
        >
          <X className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </button>

        <form onSubmit={handleSubmit} className="flex flex-col lg:flex-row gap-4 lg:gap-8">
          {/* Left Side */}
          <div className="flex-1 space-y-4 lg:space-y-6">
            <div>
              <h3 className="text-[#4f535f] text-base lg:text-lg">Your name</h3>
              <Input 
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Your name" 
                className="mt-2 bg-[#fafbff] border-none focus:ring-2 focus:ring-[#0FC585]/20 h-12 lg:h-14 text-base lg:text-lg"
              />
            </div>

            <div>
              <h3 className="text-[#4f535f] text-base lg:text-lg">Your e-mail</h3>
              <Input 
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="example@example.com" 
                className={`mt-2 bg-[#fafbff] border-none focus:ring-2 focus:ring-[#0FC585]/20 h-12 lg:h-14 text-base lg:text-lg ${
                  errors.email ? 'ring-2 ring-red-500/50' : ''
                }`}
              />
            </div>

            <div className="flex items-center gap-2 lg:gap-4 flex-wrap">
              <span className="text-[#4f535f] text-base lg:text-lg">Rating</span>
              <div className="flex gap-[6px]">
                {[1, 2, 3, 4, 5].map((star) => (
                  <button
                    type="button"
                    key={star}
                    onClick={() => setRating(star)}
                    className="transition-transform hover:scale-110 focus:outline-none"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill={star <= rating ? "#FF553C" : "#E5E7EB"}
                      xmlns="http://www.w3.org/2000/svg"
                      className="transition-colors duration-200 lg:w-6 lg:h-6"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3 0C1.34315 0 0 1.34315 0 3V17C0 18.6569 1.34315 20 3 20H17C18.6569 20 20 18.6569 20 17V3C20 1.34315 18.6569 0 17 0H3ZM13.046 11.015L15.4606 8.80857C15.6778 8.61015 15.5689 8.24855 15.2782 8.20315L13.226 7.88262L12.0419 7.69461C11.9291 7.6767 11.832 7.60495 11.7819 7.50233L11.2698 6.45534L10.3742 4.61896C10.2486 4.36135 9.88376 4.35505 9.74931 4.60816L8.24105 7.44783C8.18751 7.54865 8.08826 7.6171 7.975 7.63132L6.78507 7.78068L4.72403 8.03532C4.43221 8.07137 4.31165 8.42895 4.52207 8.63433L6.8164 10.873C6.90088 10.9553 6.93827 11.0747 6.91589 11.1905L6.69737 12.3218L6.31064 14.3016C6.25511 14.5859 6.54865 14.8112 6.80887 14.6839L9.69151 13.2746C9.78672 13.228 9.89787 13.2271 9.99389 13.2719L13.056 14.7039C13.3173 14.8262 13.6062 14.5984 13.5482 14.3158L12.939 11.3441C12.9143 11.2233 12.9549 11.0983 13.046 11.015Z"
                      />
                    </svg>
                  </button>
                ))}
              </div>
              <span className="bg-[#fafbff] px-3 lg:px-4 py-1 rounded-full text-[#4f535f] text-sm lg:text-base">
                {rating} / 5
              </span>
            </div>
          </div>

          {/* Right Side */}
          <div className="flex-1 flex flex-col">
            <div className="flex-1">
              <h3 className="text-[#4f535f] text-base lg:text-lg">Review</h3>
              <Textarea 
                name="review"
                value={formData.review}
                onChange={handleInputChange}
                placeholder="Your Review"
                className={`mt-2 bg-[#fafbff] border-none min-h-[120px] lg:h-[calc(100%-40px)] focus:ring-2 focus:ring-[#0FC585]/20 text-base lg:text-lg resize-none ${
                  errors.review ? 'ring-2 ring-red-500/50' : ''
                }`}
              />
            </div>
            
            <Button 
              type="submit"
              disabled={isSubmitting}
              className="transition-colors duration-200 px-6 lg:px-8 py-3 lg:py-4 h-auto w-full mt-4 !font-semibold md:text-md text-lg"
            >
              {isSubmitting ? "Submitting..." : "Submit Review"}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
} 