import React, { useState, useEffect } from "react";
import { useSnapshot } from "valtio";
import { SelectAccount } from "./components/select-account";
import { StepIndicator } from "./components/step-indicator";
import state, {
  Followers,
  PostBasedProduct,
  Services,
  Steps,
  ServiceSubStep,
  UserCart,
} from "./store/state";
import actions from "./store/actions";
import { ChooseService } from "./components/choose-service";
import { useMemo, useId } from "react";
import toast, { Toaster } from "react-hot-toast";
import Icon from "./components/icon";
import { AppTopLogo } from "./components/app-top-logo";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import OrderStatusPage from "./components/order-status-page";
import CheckoutPage from "./components/checkout-page";
import { BasketSummary } from "./components/basket-summary";
import { priceList } from "./config";
import PaymentPage from "./components/payment-page";
import ThreeDCallbackPage from "./components/threed-callback-page";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useLocation } from "react-router-dom";
import { HomePage } from "./HomePage";
import { Button } from "./components/ui/button";
import { FAQDrawer } from "./components/faq-drawer";
import { ProveSourceNotification } from "./components/prove-source-notification";
import { TARGET_AMOUNT } from "./components/basket-summary-stepper";
import { LandingPage } from "./pages/landing";
import { setPostHogGroups } from "./utils/analytics";
import { HelmetProvider } from "react-helmet-async";
import { TwicsyLandingPage } from "./pages/landing/twicsy-landing-page";

// Initialize PostHog
try {
  posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
    api_host: import.meta.env.VITE_POSTHOG_HOST || "https://app.posthog.com",
    loaded: (posthog) => {
      if (import.meta.env.DEV) {
        // Override feature flags in development
        // posthog.featureFlags.override({ "landing-page-conversion": "twicsy" });
      }
    },
  });
} catch (error) {
  console.error("Failed to initialize PostHog:", error);
}

function Layout({ children }: { children: React.ReactNode }) {
  const store = useSnapshot(state);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isFAQOpen, setIsFAQOpen] = useState(false);
  // Temporarily comment out hasProducts state
  // const [hasProducts, setHasProducts] = useState(false);

  // useEffect(() => {
  //   const productsExist = store.cart.some((userCart) =>
  //     Object.entries(userCart.products).some(([service, products]) => {
  //       if (service === Services.FOLLOWERS) {
  //         return (products as Followers[]).length > 0;
  //       } else {
  //         return (products as PostBasedProduct[]).some(
  //           (product) => product.posts.length > 0
  //         );
  //       }
  //     })
  //   );
  //   setHasProducts(productsExist);
  // }, [store.cart]);

  const isFirstStep = () => store.currentStep === Steps.SELECT_ACCOUNT;

  const handleBackNavigation = () => {
    const subStep = searchParams.get("subStep") as ServiceSubStep | null;
    const newParams = new URLSearchParams(searchParams);

    switch (store.currentStep) {
      case Steps.CHECKOUT:
        actions.updateCurrentStep(Steps.CHOOSE_SERVICE);
        actions.setServiceSubStep(ServiceSubStep.BASKET);
        newParams.set("subStep", ServiceSubStep.BASKET);
        break;
      case Steps.CHOOSE_SERVICE:
        if (subStep === ServiceSubStep.BASKET) {
          newParams.delete("subStep");
          actions.setServiceSubStep(ServiceSubStep.SERVICE_SELECTION);
        } else {
          setIsAlertOpen(true);
          return;
        }
        break;
      default:
        setIsAlertOpen(true);
        return;
    }

    setSearchParams(newParams);
  };

  const location = useLocation();

  const handleConfirm = () => {
    actions.resetAllState();
    setIsAlertOpen(false);
    const newParams = new URLSearchParams(searchParams);
    newParams.delete("subStep");
    setSearchParams(newParams);
    actions.updateCurrentStep(Steps.SELECT_ACCOUNT);
  };

  return (
    <div className="min-h-screen antialiased" vaul-drawer-wrapper="">
      <div className="h-[48px] flex items-center justify-between w-full fixed top-0 left-0 right-0 bg-white z-20 px-4">
        {!isFirstStep() ? (
          <div
            className="w-7 h-7 border border-gray-100 rounded-[6px] bg-gray-50 flex items-center justify-center cursor-pointer"
            onClick={handleBackNavigation}
          >
            <Icon icon="arrow-left-line" className="w-5 h-5 text-gray-900" />
          </div>
        ) : (
          <div className="w-7"></div>
        )}
        <AppTopLogo />
        <div
          className="w-[56px] h-7 border border-gray-100 rounded-[6px] bg-gray-50 flex items-center justify-center cursor-pointer gap-[2px]"
          onClick={() => setIsFAQOpen(true)}
        >
          <span className="text-body-2xs-m">FAQ</span>
          <Icon icon="question-fill" className="w-4 h-4 text-gray-900" />
        </div>
      </div>
      {children}
      <Toaster containerClassName="toaster-container" />
      <FAQDrawer open={isFAQOpen} onOpenChange={setIsFAQOpen} />
      <AlertDialog open={isAlertOpen} onOpenChange={setIsAlertOpen}>
        <AlertDialogContent className="max-w-[327px] rounded-[12px] px-4 py-5">
          <AlertDialogHeader className="space-y-1">
            <AlertDialogTitle className="!text-body-sm-m text-gray-900 text-center">
              Switch Account? 🤔
            </AlertDialogTitle>
            <AlertDialogDescription className="!text-body-2xs-r text-gray-400 text-center">
              You have services in your basket. Switching accounts will empty
              your basket. Are you sure you want to continue?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="flex flex-row justify-center items-center gap-4">
            <AlertDialogAction
              onClick={handleConfirm}
              className="bg-red-100 max-h-8 focus:bg-red-200 hover:border-red-300 hover:shadow-none focus:shadow-none !focus:ring-0 hover:ring-0 border flex-1 !border-white text-red-300 !text-body-xs-m shadow-[0px_1px_2px_0px_var(--Red-200,#F8C9D2),0px_0px_0px_1px_var(--Red-200,#F8C9D2)]"
            >
              Continue
            </AlertDialogAction>
            <AlertDialogCancel className="mt-0 flex-1 max-h-8 bg-gray-50 border-gray-100 text-body-xs-m">
              Cancel
            </AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}

function MainApp() {
  const [searchParams, setSearchParams] = useSearchParams();
  const store = useSnapshot(state);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const subStep = searchParams.get("subStep") as ServiceSubStep | null;
    if (subStep && subStep === ServiceSubStep.BASKET) {
      actions.setServiceSubStep(subStep);
    }
  }, [searchParams]);

  useEffect(() => {
    const refreshUserData = async () => {
      if (
        store.user &&
        !store.user.error &&
        store.userFeed &&
        !store.userFeed.error
      ) {
        try {
          // User'ı yeniden fetch et
          await actions.searchTikTokUser(store.user.username);

          // UserFeed'i yeniden fetch et
          if (state.user && state.accessToken) {
            await actions.fetchUserFeed(1); // 3, FEED_MAX_RETRIES değeri
          }
        } catch (error) {
          console.error("Error refreshing user data:", error);
        }
      }
    };

    refreshUserData();
  }, []);

  // calculateTotalPrice fonksiyonunu dışarı çıkaralım
  const calculateTotalPrice = (userCart: UserCart) => {
    let originalTotal = 0;
    let discountedTotal = 0;

    Object.entries(userCart.products).forEach(([service, products]) => {
      products.forEach((product) => {
        const packInfo = priceList[service as Services].find(
          (pack) => pack.id.toString() === product.service_id
        );
        if (packInfo) {
          if (service === Services.FOLLOWERS) {
            const followerProduct = product as Followers;
            originalTotal += packInfo.originalPrice * followerProduct.count;
            discountedTotal += packInfo.price * followerProduct.count;
          } else {
            const postBasedProduct = product as PostBasedProduct;
            const postCount = postBasedProduct.posts.length;
            originalTotal += packInfo.originalPrice * postCount;
            discountedTotal += packInfo.price * postCount;
          }
        }
      });
    });

    return { original: originalTotal, discounted: discountedTotal };
  };

  const handleOnContinue = () => {
    const newParams = new URLSearchParams(searchParams);
    if (store.currentStep === Steps.CHOOSE_SERVICE) {
      if (store.serviceSubStep === ServiceSubStep.SERVICE_SELECTION) {
        newParams.set("subStep", ServiceSubStep.BASKET);
        actions.setServiceSubStep(ServiceSubStep.BASKET);
      } else {
        const { user, cart } = store;
        if (user && cart) {
          const userCart = cart.find((item) => item.username === user.username);
          if (userCart) {
            const { discounted: totalAmount } = calculateTotalPrice(userCart);
            const existingCoupon = newParams.get("coupon");
            if (totalAmount >= TARGET_AMOUNT && !existingCoupon) {
              newParams.set("coupon", "Z883QUSG");
            } else if (
              totalAmount < TARGET_AMOUNT &&
              newParams.get("coupon") === "Z883QUSG"
            ) {
              newParams.delete("coupon");
            }
          }
        }

        actions.updateCurrentStep(Steps.CHECKOUT);
        newParams.delete("subStep");
      }
    }

    setSearchParams(newParams);
  };

  const handleOnBack = () => {
    const newParams = new URLSearchParams(searchParams);

    if (store.currentStep === Steps.CHECKOUT) {
      actions.updateCurrentStep(Steps.CHOOSE_SERVICE);
      actions.setServiceSubStep(ServiceSubStep.BASKET);
      newParams.set("subStep", ServiceSubStep.BASKET);
    } else if (store.serviceSubStep === ServiceSubStep.BASKET) {
      actions.setServiceSubStep(ServiceSubStep.SERVICE_SELECTION);
      newParams.delete("subStep");
    } else {
      actions.updateCurrentStep(Steps.SELECT_ACCOUNT);
      newParams.delete("subStep");
    }

    setSearchParams(newParams);
  };

  const contentComponent = useMemo(() => {
    switch (store.currentStep) {
      case Steps.SELECT_ACCOUNT:
        return <SelectAccount onContinue={handleOnContinue} />;
      case Steps.CHOOSE_SERVICE:
        return store.serviceSubStep === ServiceSubStep.SERVICE_SELECTION ? (
          <ChooseService onBack={handleOnBack} onContinue={handleOnContinue} />
        ) : (
          <CheckoutPage />
        );
      case Steps.CHECKOUT:
        return <PaymentPage />;
      default:
        return null;
    }
  }, [store.currentStep, store.serviceSubStep]);

  const renderBasketSummary = () => {
    if (
      store.showBasketSummary &&
      (store.currentStep === Steps.CHOOSE_SERVICE ||
        store.serviceSubStep === ServiceSubStep.BASKET)
    ) {
      const { user, cart } = store;
      if (!user) return null;

      const userCart = cart.find((item) => item.username === user.username);
      if (!userCart) return null;

      const getTotalItemCount = () => {
        return Object.entries(userCart.products).reduce(
          (total, [service, products]) => {
            return (
              total +
              products.reduce((sum, product) => {
                if (service === Services.FOLLOWERS) {
                  return sum + (product as Followers).count;
                } else {
                  return sum + (product as PostBasedProduct).posts.length;
                }
              }, 0)
            );
          },
          0
        );
      };

      const { original: originalPrice, discounted: discountedPrice } =
        calculateTotalPrice(userCart);

      return (
        <div className="max-w-2xl mx-auto fixed bottom-0 left-0 right-0 bg-white border-t border-gray-100">
          <BasketSummary
            itemCount={getTotalItemCount()}
            originalPrice={`$${originalPrice.toFixed(2)}`}
            discountedPrice={`$${discountedPrice.toFixed(2)}`}
            onGoToPayment={handleOnContinue}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="max-w-2xl mx-auto h-screen flex flex-col">
      <StepIndicator
        className="fixed top-[45px] z-20 bg-white left-0 right-0 max-w-2xl mx-auto [transform:translateZ(0)] [-ms-transform:translateZ(0)] [-webkit-transform:translateZ(0)] border-t border-b border-gray-100"
        currentStep={store.currentStep}
      />
      <div
        className="mt-[90px] h-full bg-gray-50 animate-in"
        key={store.currentStep + store.serviceSubStep}
      >
        {contentComponent}
      </div>
      {renderBasketSummary()}
    </div>
  );
}

export default function App() {
  useEffect(() => {
    actions.initializeUtmParams();
    setPostHogGroups();
  }, [window.location.search]); // Re-run when search parameters change

  return (
    <PostHogProvider client={posthog}>
      <HelmetProvider>
        <Router>
          <ProveSourceNotification />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/get-free-likes" element={<TwicsyLandingPage />} />
            <Route path="/get-free-views" element={<TwicsyLandingPage />} />
            <Route path="/get-free-shares" element={<TwicsyLandingPage />} />
            <Route
              path="/order/*"
              element={
                <Layout>
                  <Routes>
                    <Route index element={<MainApp />} />
                    <Route path="orderStatus" element={<OrderStatusPage />} />
                    <Route path="3DCallback" element={<ThreeDCallbackPage />} />
                  </Routes>
                </Layout>
              }
            />
          </Routes>
        </Router>
      </HelmetProvider>
    </PostHogProvider>
  );
}
