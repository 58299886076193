import { Link } from "react-router-dom";

export function Footer() {
  return (
    <footer className="bg-white border-t border-gray-100 py-6 mt-12">
      <div className="container mx-auto px-4 max-w-[1005px]">
        <div className="flex flex-col md:flex-row justify-between items-center gap-4">
          <div className="text-sm text-gray-500">
            Copyright © {new Date().getFullYear()} Famepeak. All Rights Reserved.
          </div>
          <div className="flex items-center gap-6">
            <a
              href="https://landing.famepeak.io/policiesv2/terms_of_service"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-gray-500 hover:text-gray-700 transition-colors"
            >
              Terms of Service
            </a>
            <a
              href="https://landing.famepeak.io/policiesv2/privacy_policy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-gray-500 hover:text-gray-700 transition-colors"
            >
              Privacy Policy
            </a>
            <a
              href="mailto:famepeakio@gmail.com?subject=Famepeak Support Request"
              className="text-sm text-gray-500 hover:text-gray-700 transition-colors"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
